<template>
  <div>
    <portal to="page-top-title">Domains</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'domains-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/domains" :url-query="{ with: ['ip:id,ip'] }" data-prop="domains" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>IP</th>
          <th class="text-center">Used</th>
          <th class="text-center">SSL Active</th>
          <th class="text-center">Active</th>
          <th class="text-center">Status</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="domain in domains" :key="`domains-tr-${domain.id}`" :value="domain">
          <td>{{ domain.id }}</td>
          <td>{{ domain.name }}</td>
          <td>{{ domain.ip.ip }}</td>
          <td class="text-center">
            <badge title="Count" color="gray">{{ domain.used }}</badge>
          </td>
          <td class="text-center">
            <badge-active :value="domain.ssl_active"/>
          </td>
          <td class="text-center">
            <badge-active :value="domain.active"/>
          </td>
          <td class="text-center">
            <badge-status :value="domain.status_text"/>
          </td>
          <td class="text-right">{{ domain.created_at }}</td>
          <td class="text-right">{{ domain.updated_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'domains-single', params: { id: domain.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import Badge from '@/views/components/simple/Badge'
import BadgeActive from '@/views/components/simple/BadgeActive'
import BadgeStatus from '@/views/components/simple/BadgeStatus'
import Btn from '@/views/components/simple/Btn'
import domain from '@/modules/strubs/domain'

export default {
  name: 'DomainsList',
  metaInfo: {
    title: 'Domains'
  },
  components: {
    BoxTable,
    Badge,
    BadgeActive,
    BadgeStatus,
    Btn
  },
  data () {
    return {
      domains: [domain]
    }
  }
}
</script>
